import React, {Fragment, useCallback, useContext, useEffect, useState} from "react"
import { 
    Page, 
    Layout, 
    Card,
    Icon,
    Stack,
    Heading,
    TextContainer
} from "@shopify/polaris"
import styled from 'styled-components'
import { navigate } from "gatsby";
import {SettingsMajor, CreditCardMajor, AddProductMajor, TransferWithinShopifyMajor, PaintBrushMajor} from '@shopify/polaris-icons';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import FirebaseContext from '../../../providers/firebase';
import MigrationWizardModal from "../offers/components/migrationWizardModal/migrationWizardModal";
import getRules from "../../../helpers/getRules";
const SettingWrapper = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .setting-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        .setting-icon {
            padding: 6px;
            background: #C9CCCF;
            border: 1px solid #C9CCCF;
            border-radius: 5px;
            .Polaris-Icon {
                width: 3rem;
                height: 3rem;
            }
        }
        p {
            margin-top: 0;
        }
        
    }
`

function Settings(props) {
    const { chartDateRange, selectedTabIndex, setSelectedTabIndex } = props;
    const [active, setActive] = useState(false);
    const [fetchedRules, setFetchedRules] = useState([]);
    const { firebase, shop, token, host } = useContext(FirebaseContext);
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(shopDoc);

    useEffect(() => {
        getRules(token, shop, host)
          .then((res) => {
              setFetchedRules(res.data.rules);
          });
    }, [setFetchedRules]);

    const handleChange = useCallback((prevState) => setActive(!prevState), [setActive]);
    const domain = shopData && shopData.shopData && shopData.shopData.domain
        return (
            <Page>
                <Fragment>
          <div style={{padding: '10px 0', maxWidth: '620px', paddingRight: '3rem'}}>
            <div className="Polaris-Page-Header__Row">
            <div className="Polaris-Page-Header__BreadcrumbWrapper" style={{marginRight:"1.6rem"}} onClick={() => {
                        if(3 === selectedTabIndex){
                            setSelectedTabIndex(0)
                        }else{
                          navigate('/app')
                        }
                        }}>
              <nav role="navigation"><a data-polaris-unstyled="true" className="Polaris-Breadcrumbs__Breadcrumb"><span className="Polaris-Breadcrumbs__Icon"><span className="Polaris-Icon"><span className="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2z"></path></svg></span></span><span className="Polaris-VisuallyHidden"></span></a></nav>
            </div>
              <div className="Polaris-Page-Header__TitleWrapper" style={{flex:"1 1 auto"}}>
                <div className='OfferHeaderContainer'>
                  <div className="Polaris-Header-Title__TitleAndSubtitleWrapper" style={{width: '100%'}}>
                    <div className='OfferHeaderContainer'>
                      <div>
                        <h1 className="Polaris-Header-Title" style={{fontSize:'1.5em', fontWeight:600}}>
                          Settings
                        </h1>
                        
                      </div>
                      
                    </div>
                    
                  </div>
                </div>
            </div>
          </div>
          </div>
        </Fragment>
                <Layout>
                    <Layout.Section>
                        <Card>
                            <Card.Section>
                                <Stack>
                                    <SettingWrapper>
                                        <div className="setting-item" onClick={
                                        () => {
                                            navigate(
                                                `/app/settings/checkoutOfferPosition`,
                                                {
                                                    replace: true,
                                                }
                                                )
                                        }
                                        }>
                                            <div className="setting-icon">
                                                <Icon
                                                source={SettingsMajor}
                                                color="base" />
                                            </div>
                                            <div>
                                                <TextContainer>
                                                    <Heading>Checkout.Liquid offer position</Heading>
                                                    <p>
                                                    Select where your checkout offers show
                                                    </p>
                                                </TextContainer>
                                            </div>
                                        </div>
                                        {domain ?
                                        <div className="setting-item" onClick={
                                        () => {
                                            window.open(`https://${domain}/admin/settings/checkout/editor`, '_blank')
                                        }
                                        }>
                                            <div className="setting-icon">
                                                <Icon
                                                source={SettingsMajor}
                                                color="base" />
                                            </div>
                                            <div>
                                                <TextContainer>
                                                    <Heading>Checkout Extensibility offer position</Heading>
                                                    <p>
                                                    Select where your checkout offers show
                                                    </p>
                                                </TextContainer>
                                            </div>
                                        </div>
                                        :
                                        false
                                        }
                                        {domain ?
                                        <div className="setting-item" onClick={
                                        () => {
                                            window.open(`https://${domain}/admin/settings/checkout#additionalscripts`, '_blank')
                                        }
                                        }>
                                            <div className="setting-icon">
                                                <Icon
                                                source={AddProductMajor}
                                                color="base" />
                                            </div>
                                            <div>
                                                <TextContainer>
                                                    <Heading>Post-purchase settings</Heading>
                                                    <p>
                                                    Choose UpsellPlus in your post-purchase settings
                                                    </p>
                                                </TextContainer>
                                            </div>
                                        </div>
                                        :
                                        false
                                        }
                                        {
                                            domain &&
                                                <div
                                                    className="setting-item"
                                                    onClick={() => handleChange()}
                                                >
                                                    <div className="setting-icon">
                                                        <Icon source={TransferWithinShopifyMajor} color="base"/>
                                                    </div>
                                                    <div>
                                                        <TextContainer>
                                                            <Heading>Migration wizard</Heading>
                                                            <p>Migrate your checkout.liquid upsells to checkout extensibility</p>
                                                        </TextContainer>
                                                    </div>
                                                </div>
                                        }
                                        { shopData && shopData.chargeId && !shopData.planPrice?
                                        <div className="setting-item" onClick={
                                        () => {
                                            navigate(
                                            `/app/settings/billing`,
                                            {
                                                replace: true,
                                            }
                                            )
                                        }
                                        }>
                                        <div className="setting-icon">
                                                <Icon
                                                source={CreditCardMajor}
                                                color="base" />
                                            </div>
                                            <div>
                                                <TextContainer>
                                                    <Heading>Billing</Heading>
                                                    <p>
                                                    View your billing and subscription charges
                                                    </p>
                                                </TextContainer>
                                            </div>
                                        </div>
                                        :
                                        false
                                        }
                                        <div className="setting-item" onClick={
                                        () => {
                                            navigate(
                                                `/app/settings/branding`,
                                                {
                                                    state: {
                                                        backLink: "settings",
                                                    },
                                                    replace: true,
                                                }
                                                )
                                        }
                                        }>
                                            <div className="setting-icon">
                                                <Icon
                                                source={PaintBrushMajor}
                                                color="base" />
                                            </div>
                                            <div>
                                                <TextContainer>
                                                    <Heading>Checkout branding</Heading>
                                                    <p>
                                                    Control checkout branding, style and colors
                                                    </p>
                                                </TextContainer>
                                            </div>
                                        </div>
                                    </SettingWrapper>   
                                </Stack>
                            </Card.Section>
                        </Card>
                        <MigrationWizardModal
                          active={active}
                          setActive={setActive}
                          rules={
                            fetchedRules ? fetchedRules.filter((rule) => rule.offerType === "Checkout"
                                && (!rule?.checkoutType || rule?.checkoutType !== "extension" )) : []
                          }
                          shop={shop}
                          firebase={firebase}
                        />
                    </Layout.Section>
                </Layout>
            </Page>
        )
}

export default Settings
